@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap);
body {
  color: white;
  font-family: 'Ubuntu',helvetica,arial,sans-serif !important;
  margin: 0;
  padding: 0;
  background: -webkit-gradient(linear, right top, left top, from(#aeb150), to(#61ae76));
  background: -webkit-linear-gradient(right, #aeb150, #61ae76);
  background: linear-gradient(to left, #aeb150, #61ae76); }

.App {
  text-align: center; }

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.25rem;
  color: white;
  padding-left: 30px;
  padding-right: 30px; }

.profile-holder {
  height: 320px;
  top: 218px;
  width: 250px;
  margin-bottom: 30px;
  z-index: 2;
  margin-top: 40px; }

.profile {
  -webkit-animation: fadein 2s;
          animation: fadein 2s;
  background: #fafafa;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #222222;
  font-size: 16px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  text-align: center;
  width: 100%; }
  @media (min-width: 780px) {
    .profile {
      margin-bottom: 30px; } }
  .profile:before, .profile:after {
    content: "";
    height: 98%;
    position: absolute;
    width: 100%;
    z-index: -1;
    -webkit-transition: all linear 0.18s;
    transition: all linear 0.18s; }
  .profile:before {
    background: #fafafa;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    left: -5px;
    top: 4px;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  .profile:after {
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    right: -3px;
    top: 1px;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  .profile:hover:before {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  .profile:hover:after {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  .profile__name {
    color: #1b1b1b;
    font-size: 18px;
    margin-bottom: 5px; }
  .profile__job {
    color: #828387;
    margin-top: 0;
    font-weight: normal;
    font-size: 16px; }
  .profile__me {
    display: block;
    margin: 20px auto;
    height: 100px;
    width: 100px;
    -webkit-animation: fadein 3s;
            animation: fadein 3s; }
  .profile__donate-text {
    margin-bottom: 15px;
    display: block; }
  .profile a {
    color: #54b4ca;
    text-decoration: none; }

svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0; }
  svg g {
    mix-blend-mode: lighten; }
  svg polygon {
    stroke: none;
    fill: white; }

.App-link {
  color: #61dafb;
  text-decoration: none; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

p {
  font-size: 0.9em; }

strong {
  font-weight: bold; }

.site-footer {
  padding-top: 15px;
  display: block;
  z-index: 15;
  font-size: 14.5px;
  padding-bottom: 40px;
  opacity: 0.3;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  cursor: pointer; }
  .site-footer:hover {
    opacity: 1; }

.particles {
  position: fixed;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0; }

