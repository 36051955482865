// #particles-js {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-color: darkgrey;
//   // background-image: url("");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: 50% 50%;
//   z-index: -3;
// }

.particles {
  position: fixed;
  right: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  // z-index: -1;
}