.site-footer {
	padding-top: 15px;
	display: block;
	z-index: 15;
	font-size: 14.5px;
	padding-bottom: 40px;
	opacity: 0.3;
	transition: all 0.1s linear;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}