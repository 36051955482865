body {
  color: white;
  font-family: 'Ubuntu',helvetica,arial,sans-serif !important;
  margin: 0;
  padding: 0;
  // background: linear-gradient(to bottom, #6b8ca8, #ce6ca3);
  // background: linear-gradient(165deg, #222,  #444);
  background: linear-gradient(to left, #aeb150,  #61ae76);
}

.App {
  text-align: center;
}

.App-header {
  // background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  font-size: 16px;
  line-height: 1.25rem;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}

.profile-holder {
  height: 320px;
  // margin: -125px 0 0 -125px;
  // position: absolute;
  // right: -146px;
  top: 218px;
  width: 250px;
  margin-bottom: 30px;
  z-index: 2;
  margin-top: 40px;
}

.profile {
  animation: fadein 2s;
  background: #fafafa;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: $medium-grey;
  font-size: 16px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  text-align: center;
  width: 100%;

  @media (min-width: 780px) {
    margin-bottom: 30px;
  }

  &:before, &:after {
    content: "";
    height: 98%;
    position: absolute;
    width: 100%;
    z-index: -1;
    transition: $animation;
  }
  &:before {
    background: #fafafa;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    left: -5px;
    top: 4px;
    transform: rotate(-5deg);
  }
  &:after {
    background: #f6f6f6;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    right: -3px;
    top: 1px;
    transform: rotate(3deg);
  }

  &:hover {
    &:before {
      transform: rotate(-10deg);
    }
    &:after {
      transform: rotate(10deg);
    }
  }

  &__name {
      color: #1b1b1b;
      font-size: 18px;
      margin-bottom: 5px;
    }

    &__job {
      color: #828387;
      margin-top: 0;
      font-weight: normal;
      font-size: 16px;
    }

    &__me {
      display: block;
      margin: 20px auto;
      height: 100px;
      width: 100px;
      animation: fadein 3s;
    }

&__donate-text {
  margin-bottom: 15px;
  display: block;
}


    a {
      color: #54b4ca;
      text-decoration: none;
    }

}


svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  g {
    mix-blend-mode: lighten;
  }
  polygon {
    stroke: none;
    fill: white;
  }
}


.App-link {
  color: #61dafb;
  text-decoration: none;
}

@keyframes fadein {
    0% { opacity: 0; }
    30%   { opacity: 0; }
    100%   { opacity: 1; }
}

