// @font-face {
//   font-family: 'Ubuntu';
//   font-style: normal;
//   font-weight: 400;
//   src: local('Ubuntu'), url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap) format('woff');
// }
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');
p {
  font-size: 0.9em;
}

strong {
  font-weight: bold;
}